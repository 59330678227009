class Autopilot

	constructor : (@overlayer) ->
		@pos = createVector(0.5,0.5)
		@target = createVector(0.5,0.5)
		@ending = false
		@rotation = 0
		@rotation_target = 0
	start_drag : () ->

	random_offset : (p) ->
		m = 0.5
		v = createVector random(-m, m), random(-m, m)
		v.add p
		v.x = constrain(v.x, 0, 1)
		v.y = constrain(v.y, 0, 1)
		v
	max_moves : 10000
	move : 0
	reset : (layer) ->
		@move = 0
		@target = @random_offset createVector(0.5, 0.5)
		@overlayer.current_layer().panstart createVector(@pos.x, @pos.y)
		@ending = false
		@stopped = false
	stop : (p) ->
		if not @ending and not @stopped
		# console.log p
		# p.x =
		# @move = @max_moves
			@end(p)

	round_line : (x, y, x2, y2, w) ->
		strokeWeight 0
		circle x, y, w
		circle x2, y2, w
		strokeWeight w
		line x, y, x2, y2
		strokeWeight 1

	draw_finger : (p) ->
		push()
		r = @overlayer.res
		textFont font
		fill(if @move % 2 isnt 0 then Colors.white else Colors.black)
		# fill(if random(0, 1) > 0.5 then Colors.white else Colors.black)
		textSize r * 0.2
		textAlign CENTER, TOP
		x = (p.x - 0.5) * r
		y = (p.y - 0.5) * r
		translate(x, y)
		rotate @rotation
		text ";", 0,0
		fill(if @move % 2 isnt 0 then Colors.black else Colors.white)
		text "±", 0,0


		pop()
	end : (p) ->
		@move = 0
		@target = p
		s = 0.4
		@rotation_target = Math.PI + random(-s, s)
		@ending = true
		@overlayer.current_layer().panend createVector(@pos.x, @pos.y)
	draw_end : (t) ->
		@draw_finger()
	draw : (delta, time) ->
		if @overlayer.selected_layer?
			# if @move < @max_moves
			if @stopped

			else
				r = @overlayer.res
				r2 = @overlayer.res * 0.5

				p =
					if @ending
						p5.Vector.lerp @pos, @overlayer.cursor, 0.1
					else
						p5.Vector.lerp @pos, @target, 0.03

				@rotation = lerp(@rotation, @rotation_target, 0.2)

				delta = p5.Vector.sub(p, @pos)

				@pos = p
				if @ending
					if @pos.dist(@overlayer.cursor) < 0.01
						@stopped = true
						# toner.radar.triggerAttackRelease(["C2"], "32n")
				else
					if @pos.dist(@target) < 0.17
						@move += 1

						@target = @random_offset @pos
						@rotation_target += random(-Math.PI, Math.PI) * 0.2
						if @move % 5 is 4
							@overlayer.current_layer().panend createVector(@pos.x, @pos.y), delta
						else if @move % 5 is 0
							@overlayer.current_layer().tap createVector(@pos.x, @pos.y)
							@overlayer.current_layer().panstart createVector(@pos.x, @pos.y)

				if not @ending
					@overlayer.current_layer().pan createVector(@pos.x, @pos.y), delta

				@draw_finger @pos


class OverLayer
	preload : () ->
		@vert = DEFAULT_VERTEX_SHADER
		@frag = loadStrings "src/overlay.frag"

	constructor : (@size) ->
	current_layer : () ->
		@layers[@selected_layer]
	random_point_on_canvas : () ->
		s = @res * 0.5
		createVector(random( -s, s), random( -s, s))
	init : (@layers, @element) ->
		@cursor = createVector(0.5, 0.5)
		@res = Math.min innerHeight, innerWidth
		@canvas = createCanvas @res, @res, WEBGL

		document.getElementById("header").addEventListener "mousemove", @hover.bind(@)
		setAttributes "alpha", true
		setAttributes "antialias", false

		@selected_layer = null

		@image = createGraphics @size, @size, WEBGL
		@image.setAttributes "alpha", true
		@image.setAttributes "antialias", false


		@vert = @vert.join "\n"
		@frag = @frag.join "\n"


		for k, v of @layers
			try
				@layers[k].init @layers, @image
				# layers[k].draw(1)
			catch e
				console.error e
		# @resize()

		try
			@shader = createShader @vert, @frag
			shader @shader
		catch e
			console.log e
			document.getElementsByTagName("canvas")[0].classList.add("hide");
			return @autopilot = new Autopilot @


		@shader.setUniform "tex", @image
		@shader.setUniform "clouds_tex", @layers.clouds.image

		@tex = @shader._renderer.textures[0].setInterpolation(NEAREST, NEAREST)
		@cloud_tex = @shader._renderer.textures[1].setInterpolation(NEAREST, NEAREST)

		@autopilot = new Autopilot @

		# @select_layer localStorage.getItem "selected"

		# if not @selected_layer?
		# 	@select_layer Object.keys(@layers)[0]


	resize : () ->
		# r = @element.getBoundingClientRect()
		# s = max r.width, r.height
		# resizeCanvas s, s


	draw : (delta, time) ->
		@image.push()
		@image.clear()
		@image.noStroke()
		@image.noFill()
		@image.translate @size * - 0.5, @size * - 0.5
		@image.background(0)
		@layers[@selected_layer].draw delta, time
		@image.resetShader()
		@image.pop()


		if @selected_layer is "clouds" or (@selected_layer is "game" and @layers.game.running)
			@layers.clouds.image.push()
			@layers.clouds.image.clear()
			@layers.clouds.image.noStroke()
			@layers.clouds.image.noFill()
			@layers.clouds.image.translate @size * - 0.5, @size * - 0.5
			@layers.clouds.image.background(0)
			@layers.clouds.draw delta, time, @selected_layer is "game"
			@layers.clouds.image.resetShader()
			@layers.clouds.image.pop()
			@shader.setUniform "clouds_visible", if @selected_layer is "game" then 0.5 else 1.0
		else
			@shader.setUniform "clouds_visible", 0.0


		shader @shader

		@shader.setUniform "y", 1.0
		@shader.setUniform "time", time * 0.1
		@shader.setUniform "fade", (if screen_orientation is "landscape" then 1 else 0)
		# clear()
		background 255

		# fill 255, 0, 0
		noStroke()
		# noFill()
		rect @size * - 0.5, @size * - 0.5, @size * 0.1, @size * 0.1

		resetShader()
		@autopilot.draw delta, time

	select_layer : (l) ->
		# @image.resetShader()

		if @selected_layer isnt l
			if @layers[l]?
				if @selected_layer?
					@layers[@selected_layer].defocus()
				@selected_layer = l
				@layers[@selected_layer].focus()
				console.log "selecting #{@selected_layer}"
				if l is "game" and not @autopilot.stopped
					@autopilot.stop l
				else if not @layers.game.started_once
					if not @layers[l].autopiloted?
						@layers[l].autopiloted = true
						@autopilot.reset l
					else
						@autopilot.stop()

	reset_layer : () ->
		if @selected_layer?
			@layers[@selected_layer].reset()
	step_selected : (d) ->
		ks = Object.keys @layers
		i = ks.indexOf @selected_layer
		i += d
		i =
			if i < 0 then ks.length - 1
			else i % ks.length
		@select_layer ks[i]
		# load_page Pages[i].source

	relative_pos : (e) ->
		createVector(
			constrain((e.srcEvent.layerX) / e.target.clientWidth,0, 1)
			constrain((e.srcEvent.layerY) / e.target.clientHeight, 0, 1)
		)
	relative_hover : (e) ->
		createVector(
			constrain((e.layerX) / e.target.clientWidth,0, 1)
			constrain((e.layerY) / e.target.clientHeight, 0, 1)
		)

	relative_delta : (e) ->
		createVector(
			e.velocityX / e.target.clientWidth
			e.velocityY / e.target.clientHeight
		).normalize()

	hover :(e) ->
		p = @relative_hover e
		@cursor = p

	pan : (e) ->
		# console.log e.srcEvent.offsetX, e.target.clientWidth
		p = @relative_pos e
		d = @relative_delta e
		@cursor = createVector(p.x, p.y)
		@autopilot.stop(createVector(p.x, p.y))
		@layers[@selected_layer].autopiloted = true

		@layers[@selected_layer].pan p, d




	interact : (e) ->
		toner.start()
		p = @relative_pos e
		# p = layers[selected_layer].pos_to_window p
		@cursor = createVector(p.x, p.y)
		@autopilot.stop(createVector(p.x, p.y))
		@layers[@selected_layer].autopiloted = true

		@layers[@selected_layer][e.type] p

	pixel : (p) ->
		p.x = p.x * @size
		p.y = p.y * @size
		p
	pos_to_window : (p) ->
		p.mult createVector(innerWidth, innerHeight)
		p.x = constrain (p.x - @window.pos.x), 0, @window.size.x
		p.y = constrain (p.y - @window.pos.y), 0, @window.size.y
		# console.log @window.sizeA
		# p.div @window.size
		p.x = p.x / @window.size.x
		p.y = p.y / @window.size.y
		p
	# draw_border : (win, sw = 3, p = 3) ->
	# 	noFill()
	# 	stroke 0
	# 	rect win.pos.x - p, win.pos.y - p, win.size.x + p + p, win.size.y + p + p
	blend : () ->
		@draw_window @image, @window
	pos_inside : (pos, padding = 0) ->
		collidePointRect pos.x, pos.y, padding, padding, @size - padding, @size - padding

	constrain_point : (p, w, h, x = 0, y = 0) ->
		p.x = constrain p.x, x, (x + w)
		p.y = constrain p.y, y, (y + h)
		p

	separate_circles : (pos, diameter, collider_pos, collider_diameter) ->
		if collideCircleCircle pos.x, pos.y, diameter, collider_pos.x, collider_pos.y, collider_diameter
			v = p5.Vector.sub pos, collider_pos
			v.normalize()
			v.mult (collider_diameter + diameter) * 0.5
			v.add collider_pos
			v
		else
			pos
